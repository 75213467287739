<template>
  <div data-app class="custom-color-picker">
    <div v-if="header" :class="headerClass || 'field__subheader d-flex'">
      <span
        v-if="required"
        class="field__subheader--required"
      >
        *
      </span>
      {{ header }}
      <slot name="icon" />
    </div>
    <validation-observer ref="textInput">
      <v-menu
        v-model="showColorPicker"
        :close-on-content-click="false"
        :close-on-click="true"
        transition="scale-transition"
        class="color-picker"
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{}">
          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required
            }"
            mode="eager"
          >
            <v-text-field
              :value="color"
              class="mt-4"
              :class="{'text-field-diabled': disabled}"
              dense
              readonly
              outlined
              clearable
              :error-messages="errors"
              height="30px"
              :placeholder="placeholder"
              @click:clear="clearColor"
              @click-on-append-icon="showColorPicker = true"
              @click="showColorPicker = true"
            >
              <template #append>
                <v-btn
                  class="color-picker__button"
                  small
                  icon
                  @click="showColorPicker = true"
                >
                  <v-icon size="18" color="#66788e">
                    mdi-format-color-fill
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </validation-provider>
        </template>

        <v-color-picker
          v-if="showColorPicker"
          :value="color"
          mode="hexa"
          @update:color="
            $emit('input', $event);
            $emit('update:color', $event);
          "
        />
      </v-menu>
    </validation-observer>
  </div>
</template>

<script>

export default {
  name: "CustomColorPicker",
  props: {
    header: {
      default: () => {},
      type: String,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => '',
      type: String,
    },
    disabled: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showColorPicker: false,
    };
  },

  methods: {
    clearColor() {
      this.$emit('input', null);
      this.$emit('update:color', null);
    }
  },
};
</script>

<style lang="scss">
.color-picker {
  &__button {
    margin-top: -2px;
  }
}

.color-picker-text-field {
  .v-input__slot {
    width: 150px;
  }
}
.custom-color-picker {
  .text-field-diabled {
    .v-input__control {
      .v-input__slot {
        fieldset {
          background-color: #e5eaed !important;
        }
      }
    }
  }
  .v-text-field {
    fieldset {
      border-radius: 4px !important;
      border: solid 1px #dee7ee !important;
      background-color: #ffffff !important;
    }
    input {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .v-input__slot {
      max-height: 44px !important;
      min-height: 44px !important;
      height: 44px !important;
    }
    .v-input__append-inner {
      margin-top: 11px !important;
      margin-right: -4px;
    }
    .v-icon.mdi-close {
      font-size: 20px;
      margin-top: -1px;
    }
  }
}

.field__subheader--required {
  color: #e74c3c;
}
</style>
