
<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    chartdata: {
      deep: true,
      handler(val) {
        this.$data._chart.destroy();
        this.renderChart(val, this.options)
      },
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
}
</script>
