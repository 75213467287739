
<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null
    },
    id: {
      type: String,
      default: '',
    }
  },
  watch: {
    '$refs.canvas': {
      handler() {
        if (this.$refs.canvas && this.id) {
          this.$emit('update:ref', { canvas: this.$refs.canvas, id: this.id });
        }
      }
    },
    chartdata: {
      deep: true,
      handler(val) {
        if (this.$data._chart)
          this.$data._chart.destroy();
        this.renderChart(val, this.options)
      },
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options);
    if (this.$refs.canvas && this.id) {
      this.$emit('update:ref', { canvas: this.$refs.canvas, id: this.id });
    }
  }
}
</script>
