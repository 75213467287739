var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget__image-container"},[(_vm.widget.componentName === 'app.widget.geographicheatmapwidget')?_c('img',{attrs:{"src":require("@/assets/widgets/bitmap.png"),"height":"44px","width":"71px","alt":"map chart icon"}}):(_vm.widget.componentName === 'app.widget.topemaildomainswidget'
      || _vm.widget.componentName === 'app.widget.multiplesegmentwidget'
    )?_c('img',{attrs:{"src":require("@/assets/widgets/pie-2.png"),"height":"38px","width":"38px","alt":"pie chart icon"}}):(_vm.widget.componentName === 'app.widget.campaigncountwidget')?_c('div',{staticClass:"widget__image widget-card--violet"},[_vm._v(" 8 ")]):(_vm.widget.componentName === 'app.widget.emailcountwidget')?_c('div',{staticClass:"widget__image widget-card--crimson"},[_vm._v(" 65 ")]):(_vm.widget.componentName === 'app.widget.segmentcountwidget')?_c('div',{staticClass:"widget__image widget-card--green"},[_vm._v(" 4 ")]):(_vm.widget.componentName === 'app.widget.contactcountwidget')?_c('div',{staticClass:"widget__image widget-card--blue"},[_vm._v(" 479 ")]):(_vm.widget.componentName === 'app.plugin.prescriptiondataplugin.widget.prescriptioncountwidget')?_c('div',{staticClass:"widget__image widget-card--blue"},[_vm._v(" 10.6 ")]):(_vm.widget.componentName === 'app.widget.contactbestdaywidget'
      || _vm.widget.componentName === 'app.widget.latestemailstatisticswidget'
    )?_c('img',{attrs:{"src":require("@/assets/widgets/bar-chart.png"),"height":"48px","width":"48px","alt":"bar chart icon"}}):(_vm.widget.componentName === 'app.widget.contentpreferencewidget')?_c('img',{attrs:{"src":require("@/assets/widgets/bubble-chart.png"),"height":"58px","width":"58px","alt":"bubble chart icon"}}):(_vm.widget.componentName === 'app.widget.bannerstatisticswidget'
      || _vm.widget.componentName === 'app.widget.emailtimelinewidget'
      || _vm.widget.componentName === 'app.plugin.prescriptiondataplugin.widget.prescriptionhistorywidget'
      || _vm.widget.componentName === 'app.plugin.repmanagementplugin.widget.repmanagementhistorywidget'
    )?_c('img',{attrs:{"src":require("@/assets/widgets/line-chart.png"),"height":"38px","width":"58px","alt":"line chart icon"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }