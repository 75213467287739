<template>
  <svg
    :width="width"
    :height="height"
  />
</template>

<script>
import * as d3 from 'd3';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    graph: {
      type: Object,
      default: () => ({
        zoom: 0.3,
        offsetX: 0.20,
        offsetY: 0.03,
      }),
    },
    overflow: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Number,
      default: 0,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    legendPercentage: {
      type: Number,
      default: 20,
    },
    legendFont: {
      type: Object,
      default: () => ({
        family: 'Arial',
        size: 12,
        color: '#000',
        weight: 'bold',
      }),
    },
    valueFont: {
      type: Object,
      default: () => ({
        family: 'Arial',
        size: 16,
        color: '#fff',
        weight: 'bold',
      }),
    },
    labelFont: {
      type: Object,
      default: () => ({
        family: 'Arial',
        size: 11,
        color: '#fff',
        weight: 'normal',
      }),
    },
  },
  data: () => ({
    svg: null,
  }),
  watch: {
    data: {
      deep: true,
      handler() {
        this.renderChart();

        var texts = document.querySelectorAll(".label-text")
        for (var i = 0; i < texts.length; i++) {
          this.makeBG(texts[i])
        }
      },
    }
  },
  mounted () {
    this.svg = this.$el;
    this.renderChart();

    var texts = document.querySelectorAll(".label-text")
    for (var i = 0; i < texts.length; i++) {
      this.makeBG(texts[i])
    }
  },
  methods: {
    renderChart() {
      const data = this.data;
      const {
        overflow,
        graph,
        height,
        width,
        padding,
        showLegend,
        legendPercentage,
      } = this.$props;
      // Reset the svg element to a empty state.
      this.svg.innerHTML = '';
      // Allow bubbles overflowing its SVG container in visual aspect if props(overflow) is true.
      if(overflow)
        this.svg.style.overflow = "visible";

      const bubblesWidth = showLegend ? width * (1 - (legendPercentage / 100)) : width;
      const legendWidth = width - bubblesWidth;
      const color = d3.scaleOrdinal(d3.schemeCategory20c);

      const pack = d3.pack()
          .size([bubblesWidth * graph.zoom, bubblesWidth * graph.zoom])
          .padding(padding);

      // Process the data to have a hierarchy structure;
      const root = d3.hierarchy({children: data})
          .sum(function(d) { return d.value; })
          .sort(function(a, b) { return b.value - a.value; })
          .each((d) => {
            if(d.data.label) {
              d.label = d.data.label;
              d.id = d.data.label.toLowerCase().replace(/ |\//g, "-");
            }
          });

      // Pass the data to the pack layout to calculate the distribution.
      const nodes = pack(root).leaves();

      // Call to the function that draw the bubbles.
      this.renderBubbles(bubblesWidth, nodes, color);
      // Call to the function that draw the legend.
      if (showLegend) {
        this.renderLegend(legendWidth, height, bubblesWidth, nodes, color);
      }
    },
    makeBG(elem) {
      var svgns = "http://www.w3.org/2000/svg"
      var bounds = elem.getBBox()
      var bg = document.createElementNS(svgns, "rect")
      var style = getComputedStyle(elem)
      var padding_top = 1
      var padding_left = 1
      var padding_right = 1
      var padding_bottom = 1
      bg.setAttribute("x", bounds.x - 1)
      bg.setAttribute("y", bounds.y - 1)
      bg.setAttribute("width", bounds.width + padding_left + padding_right)
      bg.setAttribute("height", bounds.height + padding_top + padding_bottom)
      bg.setAttribute("fill", style["background-color"])
      bg.setAttribute("stroke-width", "1px")
      bg.setAttribute("stroke", "black")
      if (elem.hasAttribute("transform")) {
        bg.setAttribute("transform", elem.getAttribute("transform"))
      }
      elem.parentNode.insertBefore(bg, elem)
    },
    renderBubbles(width, nodes, color) {
      const {
        graph,
        valueFont,
      } = this.$props;

      const bubbleChart = d3.select(this.svg).append("g")
          .attr("class", "bubble-chart")
          .attr("transform", function() {
            return "translate(" + (width * graph.offsetX) + "," + (width * graph.offsetY) + ")";
          });

      const node = bubbleChart.selectAll(".node")
        .data(nodes)
        .enter().append("g")
        .attr("class", "node")
        .attr("transform", function(d) {
          return "translate(" + d.x + "," + d.y + ")";
        })
        .on("click", (d) => {
          this.$emit('bubble-clicked', d);
        });

      node.append("circle")
        .attr("id", function(d) { return d.id; })
        .attr("r", function(d) { 
          const r = isNaN(d.r) ? 0 : d.r;
          return r * (1 - 0.05); 
        })
        .style("fill", function(d) { return d.data.color ? d.data.color : color(nodes.indexOf(d)); })
        .style("z-index", 1)
        .on('mouseover', function(d) {
          d3.select(this).attr("r", d.r * 1.001);
        })
        .on('mouseout', function(d) {
          const r = d.r * (1 - 0.05);
          d3.select(this).attr("r", r);
        });

      node.append("clipPath")
        .attr("id", function(d) { return "clip-" + d.id; })
        .append("use")
        .attr("xlink:href", function(d) { return "#" + d.id; });

      node.append("text")
        .attr("class", "value-text")
        .style("font-size", `${valueFont.size}px`)
        .attr("clip-path", function(d) { return "url(#clip-" + d.id + ")"; })
        .style("font-weight", () => {
          return valueFont.weight ? valueFont.weight : 600;
        })
        .style("font-family", valueFont.family)
        .style("fill", () => {
          return valueFont.color ? valueFont.color : '#000';
        })
        .style("stroke", () => {
          return valueFont.lineColor ? valueFont.lineColor : '#000';
        })
        .style("stroke-width", () => {
          return valueFont.lineWeight ? valueFont.lineWeight : 0;
        })
        .text(function(d) { return d.value; });

      // Center the texts inside the circles.
      d3.selectAll(".value-text").attr("x", function() {
        const self = d3.select(this);
        const width = self.node().getBBox().width;
        return -(width/2);
      })
      .attr("y", function() {
        return valueFont.size / 3;
      })
      .attr("id", function(d) { return d.id; })
      .on('mouseover', function(d) {
        d3.select('#' + d.id).attr("r", d.r * 1.001);
      })
      .on('mouseout', function(d) {
        const r = d.r * (1 - 0.05);
        d3.select('#' + d.id).attr("r", r);
      });

      node.append("title")
        .text(function(d) { return d.label; });
    },
    renderLegend(width, height, offset, nodes, color) {
      const {
        legendFont,
      } = this.$props;
      const bubble = d3.select('.bubble-chart');
      const bubbleHeight = bubble.node().getBBox().height;

      const legend = d3.select(this.svg).append("g")
        .attr("transform", function() { return `translate(${offset},${(bubbleHeight)* 0.05})`; })
        .attr("class", "legend");

      let textOffset = 0;
      const texts = legend.selectAll(".legend-text")
        .data(nodes)
        .enter()
        .append("g")
        .attr("transform", () => {
          const offset = textOffset;
          textOffset+= legendFont.size + 10;
          return `translate(0,${offset})`;
        })
        .on("click", (d) => this.$emit('legend-clicked', d));

      texts.append("rect")
        .attr("width", 30)
        .attr("height", legendFont.size)
        .attr("x", 0)
        .attr("y", -legendFont.size)
        .style("fill", "transparent");

      texts.append("rect")
        .attr("width", legendFont.size)
        .attr("height", legendFont.size)
        .attr("x", 0)
        .attr("y", -legendFont.size)
        .style("fill", function(d) { return d.data.color ? d.data.color : color(nodes.indexOf(d)); });

      texts.append("text")
        .style("font-size", `${legendFont.size}px`)
        .style("font-weight", () => {
          return legendFont.weight ? legendFont.weight : 600;
        })
        .style("font-family", legendFont.family)
        .style("fill", () => {
          return legendFont.color ? legendFont.color : '#000';
        })
        .style("stroke", () => {
          return legendFont.lineColor ? legendFont.lineColor : '#000';
        })
        .style("stroke-width", () => {
          return legendFont.lineWeight ? legendFont.lineWeight : 0;
        })
        .attr("x", () => { return legendFont.size + 10 })
        .attr("y", 0)
        .text((d) => { return d.label });
    },
  },
}
</script>
