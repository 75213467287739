import { render, staticRenderFns } from "./CustomColorPicker.vue?vue&type=template&id=3ccaa958"
import script from "./CustomColorPicker.vue?vue&type=script&lang=js"
export * from "./CustomColorPicker.vue?vue&type=script&lang=js"
import style0 from "./CustomColorPicker.vue?vue&type=style&index=0&id=3ccaa958&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VColorPicker,VIcon,VMenu,VTextField})
