<template>
  <AppWidgetBlock
    v-bind="{config}"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="flex-grow-1 d-flex align-center justify-center mt-2">
      <line-chart
        v-if="loaded"
        style="width: 100%"
        :height="300"
        :chartdata="chartData"
        :options="options"
      />
    </div>
  </AppWidgetBlock>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import LineChart from "@/sharedComponents/charts/LineChart";

export default {
  name: "RepManagementHistoryWidget",
  components: {
    AppWidgetBlock,
    LineChart
  },
  props: {
    config: {
      default: () => ({
        size: '2x2',
        name: 'Rep History'
      })
    },
    data: {
      default: () => [],
    }
  },
  data(){
    return {
      loaded: true,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              padding: 15,
            }
          }],
          yAxes: [{
            display: true,
            ticks: {
              padding: 15,
            },
          }]
        }
      }
    }
  },
  computed: {
    color() {
      return this.config.configuration?.color ?? '#2b84eb';
    },
    chartData() {
      return {
        datasets: [
          {
            data: this.data.data ?? [],
            datalabels: {
              display: false,
            },
            borderColor: '#3498db',
            pointBackgroundColor: this.color,
            fill: false,
            drawActiveElementsOnTop: true,
          },
        ],
        labels: this.data.label ?? [],
      };
    },
  },
};
</script>
