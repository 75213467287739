
export const dynamicBackground = {
    computed: {
        hasBackgroundColor() {
            return !!this.config.configuration?.color;
        },
        dynamicStyling() {
            const color = this.config.configuration?.color;
            return {
                '--bgImage':color ? `linear-gradient(314deg, ${color}, ${color}59)` : "linear-gradient(314deg, #137fb5, #80d6d3)"
            };
        }
    },
};