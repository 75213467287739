<template>
  <div class="widget-inputs">
    <CustomDropdown
      v-if="'choice' === item.type"
      v-show="isVisible(item)"
      :value="item.value"
      class="mb-6"
      item-text="label"
      item-value="value"
      :items="item.choiceValues"
      :placeholder="item.placeholder"
      :required="isRequired(item)"
      :header="item.label"
      header-class="input-label"
      @input="(value) => setDropdown(value, keyName)"
    />
    <CustomColorPicker
      v-else-if="'color' === item.type"
      v-show="isVisible(item)"
      :color="item.value"
      :header="item.label"
      :required="isRequired(item)"
      header-class="input-label"
      @input="(color) => setColor(color, keyName)"
    />
    <v-row v-if="'multiple_segment' === item.type">
      <v-col cols="12">
        Segments
      </v-col>
      <v-col cols="12">
        <v-row v-if="temp_widget.widgetConfig.segments.value.length">
          <v-col cols="5" style="color: black !important" class="font-weight-bold">
            Segment
          </v-col>
          <v-col cols="5" style="color: black !important" class="font-weight-bold">
            Color
          </v-col>
          <v-col cols="2" style="color: black !important" class="font-weight-bold">
            Action
          </v-col>
        </v-row>
        <v-divider v-if="temp_widget.widgetConfig.segments.value.length" />
        <v-row
          v-for="(segment, dataKey) in temp_widget.widgetConfig.segments.value"
          :key="'multi-segment-' + dataKey"
        >
          <v-col cols="5" class="pb-0 pt-0">
            <CustomDropdown
              :value="segment.id"
              class="mb-6"
              item-text="label"
              item-value="value"
              :items="item.segments"
              placeholder="Segment"
              required
              @input="(value) => setMultipleSegment(dataKey, 'id', value)"
            />
          </v-col>
          <v-col cols="5" class="pb-0 pt-0">
            <CustomColorPicker
              :color="segment.color"
              required
              @input="(color) => setMultipleSegment(dataKey, 'color', color.hex)"
            />
          </v-col>
          <v-col cols="2" class="pb-0 pt-0">
            <v-btn
              small
              class="custom-button--red mt-5"
              @click="$delete(temp_widget.widgetConfig.segments.value, dataKey)"
            >
              <v-icon color="white" size="16">
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          class="custom-button custom-button--blue px-13 mt-8"
          height="34px"
          :disabled="temp_widget.widgetConfig.segments && temp_widget.widgetConfig.segments.value.length > 4"
          @click="addMultipleSegmentItem"
        >
          Add Segment +
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomColorPicker from "@/sharedComponents/CustomColorPicker";

export default {
  name: "EditWidgetDialogInputs",
  components: {
    CustomColorPicker,
    CustomDropdown,
  },
  props: {
    temp_widget: {
      default: () => {},
    },
    item: {
      default: () => {},
    },
    keyName: {
      default: () => {},
    },
  },
  data() {
    return {
      colorPreset: [
        "#137fb5",
        "#3b98a4",
        "#dc698f",
        "#6f509f",
        "#83CA79",
        "#B22FBE",
        "#D1D62A",
      ],
      colorValue: "",
      defaultColor: "#137fb5",
    };
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.$emit("input", val);
      }
    },
  },
  mounted() {
    if (this.item.type === 'color') {
      this.defaultColor = this.colorPreset[
        Math.floor(Math.random() * this.colorPreset.length)
        ];

      // set color
      if (this.temp_widget.widgetConfig?.color?.value) {
        this.colorValue = this.temp_widget.widgetConfig.color.value;
      } else {
        this.colorValue = this.temp_widget.widgetConfig.color?.defaultValue
          ? this.temp_widget.widgetConfig.color?.value
          : this.defaultColor;
      }
    }
  },
  methods: {
    setColor(color) {
      if (!color) {
        this.colorValue = "";

        return;
      }

      this.colorValue = color.hex;
      this.$set(this.temp_widget.widgetConfig.color, "value", this.colorValue);
    },
    setDropdown(value, keyName = null) {
      if (!value && value !== 0) {
        return;
      }

      this.$set(this.temp_widget.widgetConfig[keyName], "value", value);
    },
    setMultipleSegment(keyName, segmentProp, value) {
      this.$set(this.temp_widget.widgetConfig.segments.value[keyName], segmentProp, value);
    },
    isRequired(item) {
      if ('required' in item) {
        return item.required;
      }
      if ('requiredRules' in item) {
        const rules = item.requiredRules;

        let isRequired = true;
        rules.forEach(rule => {
          if (rule.type === 'value') {
            const valueToCheck = this.temp_widget?.widgetConfig[rule.field]?.value ?? null;

            isRequired = isRequired && rule.values.includes(valueToCheck);
          }
        });

        return isRequired;
      }

      return true;
    },
    isVisible(item) {
      if ('displayRules' in item) {
        const rules = item.displayRules;

        let isVisible = true;
        rules.forEach(rule => {
          if (rule.type === 'value') {
            const valueToCheck = this.temp_widget?.widgetConfig[rule.field]?.value ?? null;

            isVisible = isVisible && rule.values.includes(valueToCheck);
          }
        });

        return isVisible;
      }

      return true;
    },
    addMultipleSegmentItem() {
      this.$set(this.temp_widget.widgetConfig.segments.value, this.temp_widget.widgetConfig.segments.value.length, {
        id: null,
        color: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker-block {
  position: relative;

  &__picker {
    position: absolute;
    top: 50px;
    left: 0;
  }
}
</style>
