<template>
  <AppWidgetBlock
    v-bind="{config}"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="flex-grow-1 d-flex align-center justify-center my-2">
      <pie-chart
        v-if="loaded"
        :height="180"
        :chartdata="chartData"
        :options="options"
      />
    </div>
    <div
      v-for="(item, index) in data"
      :key="index"
      class="d-flex flex-wrap"
    >
      <div class="d-flex flex-fill align-center mx-2">
        <div
          :style="{'backgroundColor': item.color}"
          class="legend-cube mr-1"
        />
        <div class="legend-text">
          {{ item.name }}
        </div>
      </div>
      <div class="d-flex flex-fill align-center justify-end total-number">
        {{ item.totalCount }} ({{ ((100 * item.totalCount) / allTotals).toFixed() }}%)
      </div>
    </div>
  </AppWidgetBlock>
</template>

<script>
import PieChart from "@/sharedComponents/charts/PieChart";
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import _ from "lodash";

export default {
  name: "MultipleSegmentWidget",
  components: {
    PieChart,
    AppWidgetBlock,
  },
  props: {
    config: {
      default: () => ({
        size: '1x2',
        name: 'Top Email Domains'
      })
    },
    data: {
      default: () => {},
    }
  },
  data(){
    return {
      loaded: true,
      chartData: {},
      topItems: [],
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }],
        },
        tooltips: {
          xAlign: 'center',
          yAlign: 'center',
          titleAlign: 'center',
        }
      },
      allTotals: 0,
    }
  },
  watch: {
    data: {
      deep: true,
      handler: 'setChartData',
    },
  },
  created() {
    this.setChartData();
  },
  methods: {
    setChartData() {
      const domains = Object.keys(this.data);
      if (!domains.length) {
        return;
      }

      const labels = [];
      const numbers = [];
      const backgroundColor = [];
      let domainsData = domains.map((domain) => ({
        domain,
        total: this.data[domain].totalCount,
      }));
      this.allTotals = _.sumBy(domainsData, 'total');

      Object.keys(this.data).forEach((key) => {
        labels.push(this.data[key].name);
        numbers.push(this.data[key].totalCount);
        backgroundColor.push(this.data[key].color);
      })

      this.chartData = this.$set(this, 'chartData', {
        labels: labels,
        datasets: [{
          backgroundColor: backgroundColor,
          data: numbers,
          borderWidth: 0,
          datalabels: {
            display: false
          }
        }],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.total-number {
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #66788e;
}
</style>
